<template>
  <div class="news">
    <div class="img-box pr">
      <el-image class="wi100 hi100" :src="bg" fit="cover"></el-image>
      <div class="ban-dec uf uf-ver uf-pc">
        <div class="content-box">
          <div class="tit fwb tac">应用示范</div>
        </div>
      </div>
    </div>
    <div class="content-box pt-3 pb-2">
      <div class="uf uf-pc">
        <div class="box-tit f18 fwb"><span>核心示范</span></div>
      </div>
      <div class="company-box uf uf-pj">
        <div class="c-list" style="padding-bottom: 0px" v-for="(item, index) in company" :key="index" @click="toHref(item.url)">
          <el-image :src="item.img" fit="cover" style="width: 100%;height: 200px;border-radius: 13px"></el-image>
          <div class="fwb tac mt-3">{{ item.name }}</div>
          <div class="tal mt-3" style="font-size: 12px">{{ item.desc }}</div>
        </div>
      </div>

      <div class="uf uf-pc mt-5">
        <div class="box-tit f18 fwb" style="background: #49a8d2"><span>其他示范项目</span></div>
      </div>
      <div class="pro-box mb-4">
        <el-carousel :interval="4000" :autoplay="true" type="card" height="500px">
          <el-carousel-item v-for="(item, index) in others" :key="index" >
            <div @click="toHref(item.url)">
              <el-image :src="item.img" fit="cover" style="width: 100%;height: 350px;border-radius: 13px"></el-image>
              <div class="fwb tac mt-3">{{ item.name }}</div>
              <div class="tal mt-3" style="font-size: 12px">{{ item.desc }}</div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'news',
  data () {
    return {
      bg: require('../../assets/img/application.jpg'),
      company: [
        {name: 'Meta20设计平台', img: require('../../assets/img/mh/design.png'), url: 'https://design.somfgs.cn/', desc: '平台借助云端图库、云渲染架构，可高效、易用的完成全屋房型的绘制、进行三维空间产品的布置、快速输出所需要的效果图、设计效果所见即所得，目前用户5000+'},
        {name: '制造服务数据价值评估平台', img: require('../../assets/img/mh/evaluation.png'), url: 'https://evaluation.somfgs.cn/', desc: '制造服务数据价值评估系统针对服务型制造服务系统产生的海量数据，建立数据价值评估一体化系统，涵盖评估需求管理、模型训练优化、数据价值评估与多视角分析等功能，当前评估模型数10+'},
        {name: '数据中台', img: require('../../assets/img/mh/indatax.png'), url: 'https://indatax.somfgs.cn/', desc: '为企业提供一站式的数据服务，提供异构数据柔性接入、数据管理、数据计算等功能，能够有效提高数据的管理、利用数据的效率。'},
        {name: '轴承保持器工业互联网行业看板', img: require('../../assets/img/mh/zcbcqkb.png'), url: 'https://zckb.somfgs.cn/', desc: '从多个维度展示设备分布、产量分布、日产趋势、生产态势、产量增速和产能饱和度。'},//http://47.102.135.47:9998/
      ],
      others: [
        {name: 'OMS20订单平台', img: require('../../assets/img/mh/oms.png'), url: 'https://oms.somfgs.cn/', desc: '实现订单从创建到交付的全生命周期管理，通过系统化的处理确保信息高效流转，使订单信息全流程透明化管理'},
        {name: 'IMS20智能制造平台', img: require('../../assets/img/mh/fdm.png'), url: 'https://fdm.somfgs.cn/', desc: '针对各种生产约束条件，快速制定优化的生产作业计划，缩短制造提前期，削减库存，全面提速，提前进入交货期，为生产计划提供最优解'},
        {name: '恒温智能控制平台', img: require('../../assets/img/mh/gin.png'), url: 'https://gin.somfgs.cn/#/', desc: '基于大数据挖掘的设备运行状态诊断评价系统，实现设备运行状态在线监测、设备评分的数据可视化和故障诊断'},
        {name: '食材分析平台', img: require('../../assets/img/mh/frypan.png'), url: 'https://frypan.somfgs.cn/#/', desc: '以质量预测技术为基础，以设备内的单片机为技术承载对象，结合QT上位机和数据提取上位机的软件分析'},
        {name: '轴承保持器行业标准化服务平台', img: require('../../assets/img/mh/zcbcq.png'), url: 'http://139.196.201.183:4050/', desc: '聊城轴承保持器工业互联网平台聚焦聊城轴承保持器生产集群，提供全程数据采集、工艺、质量、仓储、运输等一站式平台化服务。提供了制造设备生产数据实时汇聚、计算；生产任务分配；生产进度采集；品质巡检、抽查、质量回溯；工人手持终端智能应用；产能分析等功能。'},
        {name: '企业融资信用运维平台', img: require('../../assets/img/mh/qyxy.png'), url: 'http://139.196.201.183:4000/', desc: '通过收集、整理和分析企业的信用信息，对企业的信用状况进行客观、公正的评价，并为企业融资、招投标、市场拓展等活动提供信用支持'},
        {name: '政企融合平台', img: require('../../assets/img/mh/zqrh.png'), url: 'http://139.196.201.183:4010', desc: '协同整合政府与各类优质社会服务资源，集政务服务、产融合作、企业服务等功能于一体，为企业提供在线综合服务入口的专业化公共信息平台'},
        {name: '产能共享平台', img: require('../../assets/img/mh/cgjg.png'), url: 'http://139.196.201.183:4020/', desc: '共享生产资源和能力，提高资源利用效率，促进产业升级和经济发展'},
        {name: '采购协同平台', img: require('../../assets/img/mh/cgpt.png'), url: 'http://139.196.201.183:4030/', desc: '通过协同合作的方式，实现各企业之间采购信息和资源的共享，以降低采购成本、提高采购效率，并共同应对市场变化。'},
        {name: '数字制造', img: require('../../assets/img/mh/znzz.png'), url: 'https://www.indintel.net/', desc: '基于现代信息技术的智能化制造系统，它以产品制造数据为基础，通过信息化手段对整个生产过程进行仿真、管控和优化，并拓展到产品整个生命周期。'},
        {name: '联邦学习', img: require('../../assets/img/mh/ysjs.png'), url: 'https://lbxx.somfgs.cn', desc: '多个数据拥有方在保持数据本地化的同时，共同训练一个机器学习模型'},
        {name: '装备智能运维平台', img: require('../../assets/img/mh/zbznyw.png'), url: 'http://117.73.11.84:91/index#/login?redirect=%2Findex', desc: '装备智能运维平台是一种集成了物联网、大数据、人工智能等先进技术的综合性管理平台，旨在提高设备的运行效率、降低维护成本，并提升整体生产管理水平'},
        {name: '共享平台-微星网', img: require('../../assets/img/mh/share.png'), url: 'https://share.somfgs.cn', desc: '帮助企业快速交付业务应用需求、以最小化手工代码量实现快速上线交付，目前已经在模具制造、注塑成型、线束组装、电子加工等多个行业成功实践'},
        {name: '模塑ERP管理系统', img: require('../../assets/img/mh/code.png'), url: 'https://code.somfgs.cn', desc: '推动注塑事业部和模具事业部的数字化管理升级，全面支持客户报价、接单、生产排程、采购计划、仓储、品质、财务等作业环节的信息化管控'},
      ],
    }
  },
  methods: {
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
    },
    toHref(url){
      window.open(url, '_blank')
    }
  },
}
</script>
<!--transform: skewX(-30deg);-->
<style scoped lang="scss">
.pro-box ::v-deep .el-carousel {
  padding-top: 15px;
}
.pro-box ::v-deep .el-carousel__item {
  padding: 20px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0 0 5px #388a87;
  border: 1px solid transparent;
  transition: all .3s ease;
  cursor: pointer;
  &:hover {
    border: 1px solid #49a8d2;
  }
}
.pro-box ::v-deep .el-carousel__item.is-active {
  background: #fff;
  box-shadow: 0 0 5px #49a8d2;
  border: 2px solid transparent;
  &:hover {
    border: 2px solid #49a8d2;
  }
}
.news {
  margin-top: -50px;
}
.img-box {
  width: 100vw;
  height: 26vw;
}
.tit {
  font-size: 4vw;
  padding-bottom: 2vw;
  color: white;
  letter-spacing: 30px;
  text-shadow: 0 0 20px rgba(0,0,0,.3);
}
.content-box {
  width: 1325px;
  margin: 0 auto;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
.company-box {
  .c-list {
    padding: 20px;
    width: 23%;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0 0 5px #ffb65f;
    border: 1px solid transparent;
    transition: all .3s ease;
    cursor: pointer;
    &:hover {
      border: 1px solid #ff8900;
    }
  }
}
.n-list {
  padding: 30px 20px;
  background: #eeeeee;
  margin-bottom: 20px;
  border-radius: 10px;
  transition: all .3s ease;
  &:hover {
    box-shadow: 0 0 20px rgba(0,0,0,.2);
  }
}
.box-tit {
  width: 180px;
  line-height: 46px;
  color: #000;
  background: #ff9123;
  transform: skewX(-30deg);
  text-align: center;
  transition: all 0.3s ease;
  margin-bottom: 40px;
  margin-top: 20px;
  span {
    transform: skewX(30deg);
  }
}
.ban-dec {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .dec-box {
    text-align: center;
    color: #ffffff;
    padding: 30px;
    .dec-tit {
      display: inline-block;
      background: transparent;
      font-size: 5vw;
      letter-spacing: 5px;
      margin-bottom: 1vw;
      text-shadow: 0 0 30px rgba(0,0,0,.4);
    }

    .dec-dec {
      line-height: 35px;
      opacity: .7;
    }
  }
}
</style>
